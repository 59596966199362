
<div class="features-button-container">
  <p class="totalFoundItems"><span>{{totalFoundItems}}</span>Pickups</p>
  <button *ngIf="isPrintOptionsAllowed" class="gls-button print-all btn btn-primary" (click)="onPrintAllOrders()">Print All &nbsp;
    <span class="material-symbols-outlined">
      print_add
    </span>
  </button>
  <button *ngIf="isPrintOptionsAllowed" class="gls-button print-selected btn btn-primary" (click)="onPrintCheckedOrders()">Print &nbsp;
    <span class="material-symbols-outlined">
      Print
    </span>
  </button>
</div>
<div class="table-container">
  <div class="expandable-filter-area">
    <div class="input-wrapper">
      <input
        type="text"
        class="search-input requester-reference-search-input"
        placeholder="Requester Reference "
        id="search-requester-reference"
        [(ngModel)]="requesterReference"
        #requesterReferenceRef="ngModel"
        (keyup)="onInputChange($event)"
        (keyup.enter)="updateTable()"
      />
    </div>
  </div>
  <div class="show-active-filters-container">
    <div *ngFor="let chip of filterChips" class="filter-chips">
      {{ chip.key }}: {{ chip.value }}
      <span><img class="x-icon" src="assets/gls-xbp-x-icon.svg" (click)="removeChip(chip)" (click)="stopEvent($event)"></span>
    </div>
  </div>
  <div class="pagination-wrapper">
    <div class="pagination-controls">
      <button class="pagination-button previous-button" (click)="goToPrevPage()">
        <span class="material-symbols-outlined">
          arrow_left
        </span>
      </button>
      <span class="page-number">{{ currentPage }} of {{ totalPages }}</span>
      <button class="pagination-button next-button" (click)="goToNextPage()">
        <span class="material-symbols-outlined">
          arrow_right
        </span>
      </button>
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th class="checkbox-all">
          <input type="checkbox" class="checkbox-input" #checkAll (change)="onCheckAllChange(checkAll.checked)">
        </th>
        <th #th1 (mousedown)="startResize($event, th1)">Parcel Number<div class="resizer"></div></th>
        <th #th2 (mousedown)="startResize($event, th2)">Pick-up Date<div class="resizer"></div></th>
        <th #th3 (mousedown)="startResize($event, th3)">Requester Name<div class="resizer"></div></th>
        <th #th4 (mousedown)="startResize($event, th5)">Requester Location<div class="resizer"></div></th>
        <th #th5 (mousedown)="startResize($event, th3)">Pick-up Location<div class="resizer"></div></th>
        <th #th6 (mousedown)="startResize($event, th6)">Delivery Location<div class="resizer"></div></th>
        <th #th7 (mousedown)="startResize($event, th4)">Order Status<div class="resizer"></div></th>
        <th class="settings-icon-container"></th>
        <!-- <th class="settings-icon-container"><img class="settings-icon" src="assets/settings-icon-white.png"></th> -->
      </tr>
    </thead>
    <tbody>
      <tr class="search-row">
        <td></td>
        <td><input type="text" class="search-input" placeholder="Search..."
          id="search-parcel-number"
          [(ngModel)]="parcelNumber"
          (keyup)="onInputChange($event)"
          #parcelNumberRef="ngModel"
          (keyup.enter)="updateTable()"></td>
        <td>
          <mat-form-field id="search-pickup-date">
            <mat-date-range-input [rangePicker]="picker">
              <input id="searchRequestedPickupDateGt" matStartDate placeholder="Start date" [(ngModel)]="selectedRequestedPickupDateGt" (dateChange)="onChangePickUpDate()">
              <input id="searchRequestedPickupDateLte" matEndDate placeholder="End date" [(ngModel)]="selectedRequestedPickupDateLte" (dateChange)="onChangePickUpDate()">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker [startAt]="selectedRequestedPickupDateGt"></mat-date-range-picker>
          </mat-form-field>
          <button class="reset-pickup-date-picker" (click)="resetPickupDatePicker()">x</button>
        </td>
        <td><input type="text" class="search-input" placeholder="Search..."
          id="search-requester-name"
          [(ngModel)]="requesterName"
          #requesterNameRef="ngModel"
          (keyup)="onInputChange($event)"
          (keyup.enter)="updateTable()"></td>
        <td><input type="text" class="search-input" placeholder="Search..."
          id="search-requester-location"
          [(ngModel)]="requesterLocation"
          #requesterLocationRef="ngModel"
          (keyup)="onInputChange($event)"
          (keyup.enter)="updateTable()"></td>
        <td><input type="text" class="search-input" placeholder="Search..."
          id="search-pickup-location"
          [(ngModel)]="pickupLocation"
          #pickupLocationRef="ngModel"
          (keyup)="onInputChange($event)"
          (keyup.enter)="updateTable()"></td>
        <td><input type="text" class="search-input" placeholder="Search..."
          id="search-delivery-location"
          [(ngModel)]="deliveryLocation"
          #deliveryLocationRef="ngModel"
          (keyup)="onInputChange($event)"
          (keyup.enter)="updateTable()"></td>
          <td class="status-multi-select">
            <app-multi-select-dropdown
              [dropdownItems]="orderStatusList"
              [selectPlaceHolder]="'Select Status'"
              [defaultSelectedItems]="selectedStatuses"
              (selectedOption)="onOrderStatusSelect($event)">
            </app-multi-select-dropdown>
          </td>
        <td></td>
      </tr>
      <ng-container *ngFor="let item of adjustedFilteredOrdersList; let i = index">
        <tr [attr.id]="'my-pickups-' + (i + 1)"
        [ngClass]="{'clickable-row': item['parcelDetails']?.length > 1, 'expanded-row': expandedRows[i]}"
        (click)="item['parcelDetails']?.length > 1 ? onOrderWithMultipleParcelNumbersClick(i) : null">
          <td class="options-icons-wrapper">
            <input type="checkbox" class="checkbox-input" #checkbox [(ngModel)]="item.checked" (click)="stopClickPropagation($event)" (change)="onCheckboxChange(item, checkbox)">
          </td>
          <td class="parcel-number-column">
            <div class="parcel-number-container">
              <ng-container *ngIf="item['parcelDetails'] && item['parcelDetails'].length === 1; else hasMultipleParcels">
                {{ item['parcelDetails'][0]['parcelKey'] }}
              </ng-container>
            <ng-template #hasMultipleParcels>
              <ng-container *ngIf="item['parcelDetails'] && item['parcelDetails'].length > 1; else emptyField">
                <img [src]="expandedRows[i] ? 'assets/gls_caret_up_white.png' : 'assets/gls_caret_down.png'" class="caret-icon">
                <span class="with-caret">{{item['parcelDetails'][0]['parcelKey']}}</span>
              </ng-container>
              <ng-template #emptyField>
              </ng-template>
            </ng-template>
            </div>
          </td>

          <td>{{ item['pickUpDate'] | date:'dd.MM.yyyy'}}</td>
          <td>{{ item['requesterName']}}</td>
          <td>{{ item['requesterLocation']}}</td>
          <td>{{ item['pickupLocation']}}</td>
          <td>{{ item['deliveryLocation']}}</td>
          <td>{{ item['orderStatus']}}</td>
          <td class="options-icons-wrapper">
            <img class="view-detail-icon"  (click)="onSeeOrderDetailsClick(item)" src="assets/view-detail-icon-eye.svg">
            <!-- <img class="three-points-settings-icon" src="assets/three-points-settings-icon.png"> -->
          </td>
        </tr>
        <ng-container *ngIf="expandedRows[i]">
          <tr *ngFor="let parcel of item['parcelDetails']" class="sub-row">
            <td class="options-icons-wrapper">
              <input type="checkbox" class="checkbox-input" #checkbox [(ngModel)]="item.checked" (click)="stopClickPropagation($event)" (change)="onCheckboxChange(item, checkbox)">
            </td>
            <td>{{ parcel.parcelKey }}</td>
            <td>{{ item['pickUpDate'] | date:'dd.MM.yyyy'}}</td>
            <td>{{ item['requesterName']}}</td>
            <td>{{ item['requesterLocation']}}</td>
            <td>{{ item['pickupLocation']}}</td>
            <td>{{ item['deliveryLocation']}}</td>
            <td>{{ getParcelStatus(item, parcel.parcelKey) }}</td>
            <td class="options-icons-wrapper">
              <img class="view-detail-icon"  (click)="onSeeOrderDetailsClick(item)" src="assets/view-detail-icon-eye.svg">
              <!-- <img class="three-points-settings-icon" src="assets/three-points-settings-icon.png"> -->
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
  <div class="pagination-wrapper">
    <div class="pagination-controls">
      <button class="pagination-button previous-button" (click)="goToPrevPage()">
        <span class="material-symbols-outlined">
          arrow_left
        </span>
      </button>
      <span class="page-number">{{ currentPage }} of {{ totalPages }}</span>
      <button class="pagination-button next-button" (click)="goToNextPage()">
        <span class="material-symbols-outlined">
          arrow_right
        </span>
      </button>
    </div>
  </div>
</div>

<!-- Temporary: Modal for popup of printing all pickups -->
<div class="modal" *ngIf="showModalPrintAll" (click)="onBackdropClick($event)">
  <div class="modal-content" (click)="onModalContentClick($event)">
    <div *ngIf="ordersList.length === 0">No Pickups!</div>
    <div class="summary-title-container"><span class="material-symbols-outlined">summarize</span><span class="summary-title">Summary</span></div>
    <div class="summary-content">
      <div class="summary-message">
        <p class="summary-message" *ngIf="getOrdersWithCompleteStatus(ordersList).ordersWithCompleteStatus.length === 1">
          <span class="material-symbols-outlined check-circle-cancel-icon">
            cancel
          </span>
          {{getOrdersWithCompleteStatus(ordersList).ordersWithCompleteStatus.length}} Pickup with status COMPLETE will not be printed.
        </p>
      </div>
      <div class="summary-message">
        <p class="summary-message" *ngIf="getOrdersWithCompleteStatus(ordersList).ordersWithCompleteStatus.length > 1">
          <span class="material-symbols-outlined check-circle-cancel-icon">
            cancel
          </span>
          {{getOrdersWithCompleteStatus(ordersList).ordersWithCompleteStatus.length}} Pickups with status COMPLETE will not be printed.
        </p>
      </div>
      <div class="summary-message">
        <p class="summary-message" *ngIf="getOrdersWithCompleteStatus(ordersList).ordersProperForPrintList.length ===1">
          <span class="material-symbols-outlined check-circle-icon">
            check_circle
          </span>
          {{getOrdersWithCompleteStatus(ordersList).ordersProperForPrintList.length}} Pickup ready to print.
        </p>
      </div>
      <div class="summary-message">
        <p class="summary-message" *ngIf="getOrdersWithCompleteStatus(ordersList).ordersProperForPrintList.length > 1">
          <span class="material-symbols-outlined check-circle-icon">
            check_circle
          </span>
          {{getOrdersWithCompleteStatus(ordersList).ordersProperForPrintList.length}} Pickups ready to print.
        </p>
      </div>
    </div>

    <div class="modal-buttons-container">
      <button class="btn modal-button" [class.deactivated]="deactivatePrintButtonInModalBox" [disabled]="deactivatePrintButtonInModalBox" (click)="onPrintAllOrdersInModalBox()">Print</button>
      <button button class="btn modal-button" (click)="closeModal()">Cancel</button>
    </div>
  </div>
</div>

<!-- Temporary: Modal for popup of printing selected pickups -->
<div class="modal" *ngIf="showModalPrintChecked" (click)="onBackdropClick($event)">
  <div class="modal-content" (click)="onModalContentClick($event)">
    <div *ngIf="checkedOrdersListWithDetails.length === 0">No Pickups selected!</div>

    <div class="summary-title-container"><span class="material-symbols-outlined">summarize</span><span class="summary-title">Summary</span></div>
    <div class="summary-content">
      <div class="summary-message">
        <p class="summary-message" *ngIf="getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersWithCompleteStatus.length === 1">
          <span class="material-symbols-outlined check-circle-cancel-icon">
            cancel
          </span>
          {{getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersWithCompleteStatus.length}} Pickup with status COMPLETE will not be printed.
        </p>
      </div>
      <div class="summary-message">
        <p class="summary-message" *ngIf="getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersWithCompleteStatus.length > 1">
          <span class="material-symbols-outlined check-circle-cancel-icon">
            cancel
          </span>
          {{getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersWithCompleteStatus.length}} Pickups with status COMPLETE will not be printed.
        </p>
      </div>
      <div class="summary-message">
        <p class="summary-message" *ngIf="getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersProperForPrintList.length ===1">
          <span class="material-symbols-outlined check-circle-icon">
            check_circle
          </span>
          {{getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersProperForPrintList.length}} Pickup ready to print.
        </p>
      </div>
      <div class="summary-message">
        <p class="summary-message" *ngIf="getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersProperForPrintList.length > 1">
          <span class="material-symbols-outlined check-circle-icon">
            check_circle
          </span>
          {{getOrdersWithCompleteStatus(checkedOrdersListWithDetails).ordersProperForPrintList.length}} Pickups ready to print.
        </p>
      </div>
    </div>

    <div class="modal-buttons-container">
      <button class="btn modal-button" [class.deactivated]="deactivatePrintButtonInModalBox" [disabled]="deactivatePrintButtonInModalBox" (click)="onPrintCheckedOrdersInModalBox()">Print</button>
      <button button class="btn modal-button" (click)="closeModal()">Cancel</button>
    </div>
  </div>
</div>

<div *ngIf="showSpinner" class="spinner-container">
  <div class="ring"></div>
  <div class="ring"></div>
  <div class="ring"></div>
  <span class="printing">Printing...</span>
</div>

<div *ngIf="messageVisible" class="modal">
  <div class="modal-content" (click)="onModalContentClick($event)">
    <div class="summary-title-container"><span class="material-symbols-outlined">summarize</span><span class="summary-title">Print status</span></div>
    <div class="summary-content">
      <div class="summary-message message-wrap" >
        <span class="material-symbols-outlined" [ngClass]="{'check-circle-icon': printSucessfull, 'check-circle-cancel-icon': !printSucessfull}">
          {{ printSucessfull ? 'check_circle' : 'cancel' }}
        </span>
        {{ messageText }}
      </div>
    </div>

    <div class="modal-buttons-container">
      <button class="btn modal-button" [class.deactivated]="deactivatePrintButtonInModalBox" [disabled]="deactivatePrintButtonInModalBox" (click)="copyToClipboard(messageText)">Copy message</button>
      <button button class="btn modal-button" (click)="closeModal()">Close</button>
    </div>
  </div>
</div>
