import { Component, OnInit, ViewChild } from '@angular/core';
import { PaxOrderService } from '../../services/pax-order.service';
import { NavigationEnd, Router } from '@angular/router';
import { CheckUserRolesService } from '../../services/check-user-roles.service';
import { Observable, Subscription, filter } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PopupMessageComponent } from '../popup-message/popup-message.component';

@Component({
  selector: 'app-my-orders-view',
  templateUrl: './my-orders-view.component.html',
  styleUrls: ['./my-orders-view.component.scss'],
  providers: [PaxOrderService, DatePipe],
})
export class MyOrdersViewComponent implements OnInit {
  accessToken$: Observable<unknown>;

  currentPage = 1;
  totalPages: number;
  totalFoundItems: number;

  queryParameters: any = {};
  parcelNumber: string = '';
  tenantKey: string = null;
  pickUpDate: string = '';
  creationDate: string = '';
  insertTimeStampGt: any | Date = '';
  insertTimeStampLte: any | Date = '';
  pickupLocation: string = '';
  orderStatus: string = '';
  requesterName: string = '';
  requesterCountry: string = '';
  requesterReference: string = '';
  requesterLocation: string = '';
  deliveryLocation: string = '';
  selectedRequestedPickupDateGt = '';
  selectedRequestedPickupDateLte: string = '';
  requestedPickupDateGt: string = '';
  requestedPickupDateLte: string = '';
  selectedRequestedCreationDateGt = '';
  selectedRequestedCreationDateLte: string = '';
  requestedCreationDateGt: string = '';
  requestedCreationDateLte: string = '';
  isGroupAdmin: boolean = false;
  limit: number = 30;
  offset: number = 0;
  ordersList: any = [];
  adjustedFilteredOrdersList = [];
  dataNotLoaded: boolean = false;
  timerForTyping: any = false;
  isPaxOrderCreator: boolean = false;

  isResizing: boolean = false;
  startingX: number = 0;
  startingWidth: number = 0;
  resizingMouseMove: any;
  resizingMouseUp: any;

  currentPath: string = '';
  private subscription: Subscription;

  orderAsTemplate: any;

  expandedRows: { [key: number]: boolean } = {};
  orderStatusList: string[] = ['NEW', 'IN_PROGRESS', 'PICKED_UP', 'COMPLETE', 'CANCELLED'];
  selectedStatuses: string[] = [];
  filterChips: { key: string, value: string }[] = [];

  popoverIndex: number | null = null;


  constructor(private paxOrderService: PaxOrderService,
    private checkUserRolesService: CheckUserRolesService,
    private router: Router,
    private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.selectedRequestedCreationDateGt = this.getStandardDate(-9);
    console.log("this.selectedRequestedCreationDateGt: ",this.selectedRequestedCreationDateGt);
    this.selectedRequestedCreationDateLte = this.getStandardDate(1);
    this.requestedCreationDateGt = this.getStandardDate(-9) + "T23:59:59Z";
    this.requestedCreationDateLte = this.getStandardDate(1) + "T23:59:59Z";
    this.tenantKey = this.checkUserRolesService.getTenantKey();
    this.isGroupAdmin = this.checkUserRolesService.isGroupAccount;
    this.isPaxOrderCreator = this.checkUserRolesService.getIsPaxOrderCreator();
    this.getCurrentPath();
    this.updateTable();
  }

  getCurrentPath() {
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      let navigationEvent = event as NavigationEnd;
      this.currentPath = navigationEvent.url;
    });
  }

  setQueryParameters() {
    if (this.parcelNumber !== '') {
      this.queryParameters['parcel-key'] = this.parcelNumber.trim();
    }

    if (this.requestedCreationDateLte !== '') {
      this.queryParameters['insert-timestamp-lte'] = this.requestedCreationDateLte.trim();
    }

    if (this.requestedCreationDateGt !== '') {
      this.queryParameters['insert-timestamp-gt'] = this.requestedCreationDateGt.trim();
    }

    if (this.requestedPickupDateLte !== '') {
      this.queryParameters['requested-pickup-date-lte'] = this.requestedPickupDateLte.trim();
    }

    if (this.requestedPickupDateGt !== '') {
      this.queryParameters['requested-pickup-date-gt'] = this.requestedPickupDateGt.trim();
    }

    if (this.pickupLocation !== '') {
      this.queryParameters['pickup-location'] = this.pickupLocation.trim();
    }

    if (this.requesterName !== '') {
      this.queryParameters['requester-name'] = this.requesterName.trim();
    }

    if (this.requesterReference !== '') {
      this.queryParameters['requester-reference'] = this.requesterReference.trim();
    }

    if (this.requesterLocation !== '') {
      this.queryParameters['requester-location'] = this.requesterLocation.trim();
    }

    if (this.pickupLocation !== '') {
      this.queryParameters['pickup-location'] = this.pickupLocation.trim();
    }

    if (this.deliveryLocation !== '') {
      this.queryParameters['delivery-location'] = this.deliveryLocation.trim();
    }

    if (this.orderStatus !== '') {
      this.queryParameters['operational-order-status'] = this.orderStatus;
    }

    this.queryParameters['limit'] = this.limit;
    this.queryParameters['offset'] = this.offset;
    this.queryParameters['sort'] = "-upserttimestamp";
    if(!this.isGroupAdmin) {
      this.queryParameters['requester-tenant-keys'] = this.tenantKey;
    }

    this.updateDisplayFilterChips();
  }

  updateDisplayFilterChips() {
    this.filterChips = [];

    const parametersToCheck = [
      { key: 'Parcel Number', value: this.parcelNumber },
      { key: 'Creation Date - Start', value: this.datePipe.transform(this.requestedCreationDateGt, 'dd.MM.yyyy') },
      { key: 'Creation Date - End', value: this.datePipe.transform(this.requestedCreationDateLte, 'dd.MM.yyyy') },
      { key: 'Pick-up Date - Start', value: this.datePipe.transform(this.requestedPickupDateGt, 'dd.MM.yyyy') },
      { key: 'Pick-up Date - End', value: this.datePipe.transform(this.requestedPickupDateLte, 'dd.MM.yyyy') },
      { key: 'Pick-up Location', value: this.pickupLocation },
      { key: 'Requester Name', value: this.requesterName },
      { key: 'Requester Reference', value: this.requesterReference },
      { key: 'Requester Location', value: this.requesterLocation },
      { key: 'Delivery Location', value: this.deliveryLocation },
    ];

    // Add individual status chips
    this.selectedStatuses.forEach(status => {
      this.filterChips.push({ key: 'Order Status', value: status });
    });

    parametersToCheck.forEach(param => {
      if (param.value && param.value !== '') {
        this.filterChips.push({ key: param.key, value: param.value });
      }
    });
  }


  removeChip(chip: { key: string, value: string }) {
    switch (chip.key) {
      case 'Parcel Number':
        this.parcelNumber = '';
        break;
      case 'Creation Date - Start':
        this.requestedCreationDateGt = '';
        this.selectedRequestedCreationDateGt = '';
        break;
      case 'Creation Date - End':
        this.requestedCreationDateLte = '';
        this.selectedRequestedCreationDateLte = '';
        break;
      case 'Pick-up Date - Start':
        this.requestedPickupDateGt = '';
        this.selectedRequestedPickupDateGt = '';
        break;
      case 'Pick-up Date - End':
        this.requestedPickupDateLte = '';
        this.selectedRequestedPickupDateLte = '';
        break;
      case 'Pick-up Location':
        this.pickupLocation = '';
        break;
      case 'Requester Name':
        this.requesterName = '';
        break;
      case 'Requester Reference':
        this.requesterReference = '';
        break;
      case 'Requester Location':
        this.requesterLocation = '';
        break;
      case 'Delivery Location':
        this.deliveryLocation = '';
        break;
      case 'Order Status':
        this.selectedStatuses = this.selectedStatuses.filter(status => status !== chip.value);
        this.orderStatus = this.selectedStatuses.join(',');
        break;
    }

    this.updateDisplayFilterChips();
    this.updateTable();
  }




  /**Function to adjust filtered ordered*/
  getAdjustedFilteredOrdersList() {
    this.adjustedFilteredOrdersList = [];

    for (let order of this.ordersList) {
      const parcelDetailsExist = order.parcelDetails && order.parcelDetails.length > 0;
      this.adjustedFilteredOrdersList.push({
        paxOrderKey: order.paxOrderKey,
        parcelNumber: parcelDetailsExist ? order.parcelDetails[0]['parcelKey'] : 'Pending',
        creationDate: this.dateOfinsertTimestamp(order.insertTimestamp),
        pickUpDate: order.requestedPickupDate,
        requesterName: order.requester['name'],
        requesterReference: order['requesterReference'],
        requesterLocation: order['requesterLocation'],
        pickupLocation: order['pickupLocation'],
        deliveryLocation: order['deliveryLocation'],
        orderStatus: order['operationalOrderStatus'],
        parcelDetails: parcelDetailsExist ? order.parcelDetails : [],
        statusKey: order.statusKey
      });
    }

    console.log(this.adjustedFilteredOrdersList)

    this.setQueryParameters();
  }



  onOrderWithMultipleParcelNumbersClick(index: number) {
    this.expandedRows[index] = !this.expandedRows[index];
  }

  getParcelStatus(item: any, parcel: string): string {
    if (!item['parcelDetails']) {
      return '';
    }
    const parcelDetail = item['parcelDetails'].find((detail: any) => detail.parcelKey === parcel);
    return parcelDetail ? parcelDetail.operationalParcelStatus : '';
  }

  updateTable(): void {
    this.currentPage = 1;
    this.queryParameters = {};
    this.setQueryParameters();
    console.log(this.queryParameters);
    this.queryParameters['offset'] = 0;
    this.queryParameters['limit'] = this.limit;
    this.offset = 0;
    this.expandedRows = {};
    this.paxOrderService.getOrders(this.queryParameters).subscribe(
      (response) => {
        this.ordersList = [];
        this.ordersList = response['data'];
        console.log(this.ordersList);
        this.getAdjustedFilteredOrdersList();
        this.limit = response['limit'];
        this.totalFoundItems = response['total'];
        this.dataNotLoaded = false;
        this.totalPages = Math.ceil(this.totalFoundItems / this.limit);
      }, error => {
        this.dataNotLoaded = true;
        this.ordersList = [];
        this.getAdjustedFilteredOrdersList();
        this.totalFoundItems = 0;
      });
  }

  pageChanged() {
    this.offset = (this.currentPage - 1) * this.limit;
    this.queryParameters = {};
    this.queryParameters['limit'] = this.limit;
    this.queryParameters['offset'] = this.offset;
    this.limit = this.queryParameters['limit'];
    this.expandedRows = {};
    this.setQueryParameters();
    this.paxOrderService.getOrders(this.queryParameters).subscribe(
      (response) => {
        this.ordersList = [];
        this.ordersList = response['data'];
        this.getAdjustedFilteredOrdersList();
        this.limit = response['limit'];
        this.totalFoundItems = response['total'];
        this.totalPages = Math.ceil(this.totalFoundItems / this.limit);
        this.dataNotLoaded = false;
      }, error => {
        this.dataNotLoaded = true;
      });
  }

  goToPrevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.pageChanged();
  }

  goToNextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
    this.pageChanged();
  }
  /**Resizing logic*/
  startResize(event: MouseEvent, th: HTMLElement): void {
    this.isResizing = true;
    this.startingX = event.pageX;
    this.startingWidth = th.offsetWidth;

    this.resizingMouseMove = this.performResize.bind(this, th);
    this.resizingMouseUp = this.stopResize.bind(this);

    document.addEventListener('mousemove', this.resizingMouseMove);
    document.addEventListener('mouseup', this.resizingMouseUp);
  }

  performResize(th: HTMLElement, event: MouseEvent): void {
    if (this.isResizing) {
      const movementX = event.pageX - this.startingX;
      th.style.width = `${this.startingWidth + movementX}px`;
    }
  }

  stopResize(): void {
    this.isResizing = false;

    if (this.resizingMouseMove) {
      document.removeEventListener('mousemove', this.resizingMouseMove);
    }
    if (this.resizingMouseUp) {
      document.removeEventListener('mouseup', this.resizingMouseUp);
    }
  }

  /**Function to get only date from the response insertTimestamp*/
  dateOfinsertTimestamp(insertTimestamp: any) {
    return insertTimestamp ? insertTimestamp.substr(0, 10) : '';
  }

  /**Function to trigger searching with a specific timeout.*/
  onInputChange(event: any) {
    clearTimeout(this.timerForTyping);
    this.timerForTyping = setTimeout(() => {
      this.updateTable();
    }, 600);
  }

  /**Function to get pickup date input from user*/
  onChangePickUpDate() {
    this.requestedPickupDateGt = '';
    this.requestedPickupDateLte = '';

    if (this.selectedRequestedPickupDateGt) {
      this.requestedPickupDateGt = this.getPreviousDate(this.selectedRequestedPickupDateGt);
      this.requestedPickupDateGt = this.datePipe.transform(this.requestedPickupDateGt, 'yyyy-MM-dd');
    }

    if (this.selectedRequestedPickupDateLte) {
      this.requestedPickupDateLte = this.datePipe.transform(this.selectedRequestedPickupDateLte, 'yyyy-MM-dd');
    }

    this.timerForTyping = setTimeout(() => {
      this.updateTable();
    }, 600);
  }

  /**Function to get pickup date input from user*/
  onChangeCreationDate() {
    this.requestedCreationDateGt = '';
    this.requestedCreationDateLte = '';

    if (this.selectedRequestedCreationDateGt) {
      this.requestedCreationDateGt = this.getPreviousDate(this.selectedRequestedCreationDateGt);
      this.requestedCreationDateGt = this.datePipe.transform(this.requestedCreationDateGt, 'yyyy-MM-dd') + "T23:59:59Z";
    }

    if (this.selectedRequestedCreationDateLte) {
      this.requestedCreationDateLte = this.datePipe.transform(this.selectedRequestedCreationDateLte, 'yyyy-MM-dd') + "T23:59:59Z";
    }

    this.timerForTyping = setTimeout(() => {
      this.updateTable();
    }, 600);
  }


  /**Function to return the previous date*/
  getPreviousDate(date: string) {
    let mainDate = new Date(date);
    let previousDay = new Date(mainDate.setDate(mainDate.getDate() - 1));
    let day: any = previousDay.getDate();
    let month: any = previousDay.getMonth() + 1;
    let year = previousDay.getFullYear();
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year + "-" + month + "-" + day;
  }


  /**Function to go to createPaxOrder Page and use orders as a template*/
  useExistingOrderAsTemplate(item: any) {
    let selectedOrder = this.ordersList.filter((order: any) => order['paxOrderKey'] === item.paxOrderKey);
    localStorage.setItem("paxOrder", JSON.stringify(selectedOrder[0]));
    localStorage.setItem("fromTemplateAction", "true");
    this.router.navigate(['/create-order']);
  }


  /** Helper function to format a Date object into a 'YYYY-MM-DD' string */
  formatDate(date: Date): string {
    let d = new Date(date.getTime() - date.getTimezoneOffset() * 60000); //Remove offset due to UTC and local time issue
    return d.toISOString().split('T')[0];
  }

  resetPickupDatePicker() {
    this.requestedPickupDateGt = '';
    this.requestedPickupDateLte = '';
    this.selectedRequestedPickupDateGt = '';
    this.selectedRequestedPickupDateLte = '';
    this.updateTable();
  }

  resetCreationDatePicker() {
    this.requestedCreationDateGt = '';
    this.requestedCreationDateLte = '';
    this.selectedRequestedCreationDateGt = '';
    this.selectedRequestedCreationDateLte = '';
    this.updateTable();
  }

  /**Pick-up Date functionality :
 without parameter sets standardDate to today,
 with parameter adds the toAddDays to current date.
 The result date will be yyyy-mm-dd */
  getStandardDate(toAddDays: number = 0): string {
    let date = new Date();
    date.setDate(date.getDate() + toAddDays);

    // If it's Saturday, add two days
    if (date.getDay() === 6) {
      date.setDate(date.getDate() + 2);
    }
    // If it's Sunday, add one day
    else if (date.getDay() === 0) {
      date.setDate(date.getDate() + 1);
    }

    return date.toISOString().split('T')[0];
  }

  onSeeOrderDetailsClick(item: any) {
    let selectedOrder = this.ordersList.filter((order: any) => order['paxOrderKey'] === item.paxOrderKey);
    localStorage.setItem("seePaxOrderDetails", JSON.stringify(selectedOrder[0]));
    console.log(selectedOrder[0]);
    this.router.navigate(['/order-details']);
  }

  stopEvent(event: Event) {
    event.stopPropagation();
  }

  onOrderStatusSelect(selectedStatuses: string[]) {
    this.selectedStatuses = selectedStatuses;
    this.orderStatus = this.selectedStatuses.join(',');
    this.updateDisplayFilterChips();
    this.updateTable();
  }

  onPendingParcelClick(item: any) {
    if (item.statusKey !== 'PARCELS_CREATED') {
      this.showAlert(`You are still able to edit this order.`);
    }
  }

  onEditOrderClick(item: any) {
    let selectedOrder = this.ordersList.filter((order: any) => order['paxOrderKey'] === item.paxOrderKey);
    localStorage.setItem("seePaxOrderDetails", JSON.stringify(selectedOrder[0]));
    console.log(selectedOrder[0]);
    this.router.navigate(['/edit-order']);
  }

  onOptionsClick(event: MouseEvent, index: number) {
    event.stopPropagation();
    this.popoverIndex = this.popoverIndex === index ? null : index;
    if (this.popoverIndex !== null) {
      document.addEventListener('click', this.documentClickListener);
    } else {
      document.removeEventListener('click', this.documentClickListener);
    }
  }

  documentClickListener = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest('.popup') && !target.closest('.three-points-settings-icon')) {
      this.popoverIndex = null;
      document.removeEventListener('click', this.documentClickListener);
    }
  };


  @ViewChild('alertPopup') alertPopup: PopupMessageComponent;

  showAlert(message: any): void {
    this.alertPopup.showMessage(message);
  }
}


