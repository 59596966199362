import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { protectedResources } from '../../../auth-b2c-config';
import { Router, NavigationEnd } from '@angular/router';
import { CheckUserRolesService } from '../../services/check-user-roles.service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  /** TODO Refactor logic if all guards are defined so depending on route the right combination is selected  */
  loginDisplay = false;
  ordersDisplay = true;
  pickupsDisplay = true;
  createDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  currentPath: string = '';
  private subscription: Subscription;

  constructor(
    private broadcastService: MsalBroadcastService,
    private MsalService: MsalService,
    private authService: AuthService,
    private router: Router,
    private userRolesSerivce: CheckUserRolesService) { }

  ngOnInit() {
    this.getCurrentPath();

    /**This function used to check the user is loggedin. If so it will set the boolean value of logout button */
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        /**Boolean value set for logout button */
        this.setLogoutDisplay();
      })
  }

  getCurrentPath() {
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      let navigationEvent = event as NavigationEnd;
      this.currentPath = navigationEvent.url;
    });
  }

  /**This function used to logout to the localhost:4200 and removes the idtoken */
  logout() {
    const isConfirmed = confirm("Are you sure you want to log out?");
    if (isConfirmed) {
        this.MsalService.logoutRedirect({ postLogoutRedirectUri: protectedResources.paxApi.endpoint });
        localStorage.clear();
    }
  }

  /**This function used to set login logout button display */
  setLogoutDisplay() {
    return this.authService.isLoggedIn();
  }

  /**Getter function for the user role PaxOrderCreator */
  isPaxOrderCreator() {
    return this.userRolesSerivce.isPaxOrderCreator
  }

  /**Getter function for the user role PaxOrderViewer */
  isPaxOrderViewer() {
    return this.userRolesSerivce.isPaxOrderViewer;
  }

  /** TODO add functionality when role for printer is added */
  isPaxOrderPrinter() {
    return true;
  }

  /**This function used to destroy the subscription to avoid duplicates */
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
