<header class="header">
  <h3 class="header__title">
    <div class="titel-contaiter">
      <a routerLink="/home">
        <img src="assets/gls-group-logo.svg" alt="" width="110" style="margin-right: 50px;">
      </a>
      <a href="/" class="title" style="padding-top: 50px; color: #ffffff">XB+ PAX</a>
    </div>
  </h3>
  <div class="menu-items">
    <div class="navbar-controls">
      <div class="navbar-page-controls">
        <button class="btn btn-secondary" *ngIf="setLogoutDisplay && createDisplay && isPaxOrderCreator()" routerLink="/create-orders">New Order</button>
        <button class="btn btn-secondary" *ngIf="currentPath?.length && currentPath.includes('/my-pickups') === true || currentPath?.length && currentPath.includes('/create-order') === true" routerLink="/my-orders">
          My Orders &nbsp;
          <span class="material-symbols-outlined">
            lists
          </span>
        </button>
        <button class="btn btn-secondary" *ngIf="currentPath?.length && currentPath.includes('/my-orders') === true" routerLink="/my-pickups">
          My Pickups &nbsp;
          <span class="material-symbols-outlined">
            package_2
          </span>
        </button>
      </div>
      <button class="btn btn-secondary" *ngIf="isLoggedIn" (click)="logout()">
        Logout &nbsp;
        <span class="material-symbols-outlined">
          logout
          </span>
      </button>
    </div>
  </div>
</header>
