import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { selectAccessToken } from 'src/app/store/auth.selectors';
import { clearAccessToken } from 'src/app/store/auth.actions';
import { MsalService } from '@azure/msal-angular';
import { protectedResources } from 'src/app/auth-b2c-config';

@Injectable({
  providedIn: 'root'
})
export class CheckUserRolesService {
  isGroupAccount: boolean = false;
  isPaxOrderViewer: boolean = false;
  isPaxOrderCreator: boolean = false;
  isPaxOrderPrinter: boolean = false;
  tenantKey: string = '';
  allowedLocationCodes: string[] = [];
  private accessToken: string | null = null;
  private tokenExpirationTime: any = '';

  constructor(private router: Router,
              private store: Store,
              private authService: MsalService) {
    this.initAccessTokenSubscription();
  }

  /** Initialize subscription to access token */
  private initAccessTokenSubscription(): void {
    this.store.select(selectAccessToken).pipe(
      tap(accessToken => {
        if (accessToken) {
          this.accessToken = accessToken;
          this.extractRolesFromToken();
          this.extractTokenExpirationTime();
        }
      })
    ).subscribe();
  }

  /** Helper function to get the tenant key without using async functions */
  private extractRolesFromToken() {
    if (this.accessToken !== null) {
      const jwtData = this.accessToken.split('.')[1];
      const decodedJwtJsonData = window.atob(jwtData);
      const decodedJwtData = JSON.parse(decodedJwtJsonData);
      const issuer = decodedJwtData['iss'];
      let roles = [];

      // if(issuer.toLowerCase().includes('b2clogin')) {
      //   roles = decodedJwtData['roles'].filter(role => role.toLowerCase().includes('xbp_pax'));
      // } else {
        roles = decodedJwtData['groups'].filter(role => role.toLowerCase().includes('xbp_pax'));
      // }

      roles
        .forEach(role => {
          const roleComponents = role.split('_');
          this.setTenantPermission(roleComponents);
          const locationCode = roleComponents[6];
          if (locationCode && !this.allowedLocationCodes.includes(locationCode)) {
            this.allowedLocationCodes.push(locationCode);
          }
          // Check for print permissions
          this.setPermissions(roleComponents);
        });
    }
  }

  private extractTokenExpirationTime() {
    if (this.accessToken !== null) {
      const jwtData = this.accessToken.split('.')[1];
      const decodedJwtJsonData = window.atob(jwtData);
      const decodedJwtData = JSON.parse(decodedJwtJsonData);
      this.tokenExpirationTime = decodedJwtData['exp'];
    }

    if (this.tokenExpirationTime) {
      const expirationDate = new Date(this.tokenExpirationTime * 1000);
      const currentDate = new Date();
      const timeDifference = expirationDate.getTime() - currentDate.getTime();

      if (timeDifference > 0) {
        setTimeout(() => {
          this.clearTokenAndRedirect();
        }, timeDifference);
      } else {
        this.clearTokenAndRedirect();
      }

      const minutesUntilExpiration = Math.floor(timeDifference / 1000 / 60);
      console.log(`Token expires in ${minutesUntilExpiration} minutes.`);
    }
  }

  private clearTokenAndRedirect() {
    this.authService.logoutRedirect({ postLogoutRedirectUri: protectedResources.paxApi.endpoint });
    localStorage.removeItem('accessToken');
    this.accessToken = null;
    this.store.dispatch(clearAccessToken());
    this.router.navigate(['/login']);
  }

  private setTenantPermission(roleComponents: any) {
    if (roleComponents[4]?.toLowerCase().includes("group")) {
      this.isGroupAccount = true;
    } else {
      this.tenantKey = `${roleComponents[4]}_${roleComponents[5]}`;
    }
  }

  private setPermissions(roleComponents: any) {
    // Check for printer permissions
    if (roleComponents[3]?.toLowerCase().includes("printer")) {
      this.isPaxOrderPrinter = true;
    }
    // Check for viewer permissions
    if (roleComponents[3]?.toLowerCase().includes("viewer")) {
      this.isPaxOrderViewer = true;
    }
    // Check for creator permissions
    if (roleComponents[3]?.toLowerCase().includes("creator")) {
      this.isPaxOrderCreator = true;
    }
  }

  /** Getter for tenantKey */
  getTenantKey(): string {
    return this.tenantKey;
  }

  /** Getter for isPrintOptionsAllowed */
  getIsPrintOptionsAllowed(): boolean {
    return this.isPaxOrderPrinter;
  }

  /** Getter for allowedLocationCodes */
  getAllowedLocationCodes(): string[] {
    return this.allowedLocationCodes;
  }

  /** Getter for isPaxOrderViewer */
  getIsPaxOrderViewer(): boolean {
    return this.isPaxOrderViewer;
  }

  /** Getter for isPaxOrderCreator */
  getIsPaxOrderCreator(): boolean {
    return this.isPaxOrderCreator;
  }

  /** Getter for isPaxOrderPrinter */
  getIsPaxOrderPrinter(): boolean {
    return this.isPaxOrderPrinter;
  }

  getIsGroupAccount(): boolean {
    return this.isGroupAccount;
  }
}
